import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { renew } from "../API/Api";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
const SiteAuthorisation = (props) => {
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  // useEffect(() => {
  //   const userinfo = JSON.parse(localStorage.getItem("userinfo"));
  //   const newSocket = io("https://ccfr-apis.cubettech.in");
  //   console.log("here newSocket+++++++++++", newSocket);
  //   if (newSocket) {
  //     newSocket.on("message", (arg) => {
  //       console.log("SOCKET HERE IS IMPLEMENTED:-", arg); // world
  //     });
  //   }
  //   setSocket(newSocket);
  //   localStorage.setItem("socket", JSON.stringify(socket));
  //   console.log("userinfo?.id", userinfo?.id);
  //   socket.on(userinfo?.id.toString(), (arg) => {
  //     console.log("SOCKET HERE IS IMPLEMENTED:-", arg); // world
  //   });
  // }, []);

  useEffect(() => {
    const token = localStorage.getItem("Token");
    const refreshToken = localStorage.getItem("refreshToken");
    const email = localStorage.getItem("email");

    if (!token || token === undefined) {
      navigate("/login");
    } else {
      const response = renew({ email: email, refreshToken: refreshToken });
      response.then(
        function (result) {
          localStorage.removeItem("Token");
          localStorage.setItem("Token", result.data.token);
        },
        (err) => {
          localStorage.clear();
          // toast.info("Sorry, you have been logged out. Please login again.");
          navigate("/login");
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ...props.children };
};

export default SiteAuthorisation;
