import axios from "axios";
export const baseURL = process.env.REACT_APP_BASE_URL+ "/ccfr/api/v1";
const client = axios.create({
  baseURL,
});

const onSuccess = function (response) {
  return response.data;
};

const onError = function (error) {
  return Promise.reject(error.response || error.message);
};

export const request = function (options) {
  return client(options).then(onSuccess).catch(onError);
};

export const authRequest = function (options) {
  const key = localStorage.getItem("Token");
  // options.headers = { "x-access-token": key };
  options.headers = { Authorization: `Bearer ${key}` };
  return client(options).then(onSuccess).catch(onError);
};
