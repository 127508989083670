import { Suspense, memo, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./css/index.css";
import history from "./helpers/history";
import AppRoutes from "./routes/AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeContext } from "./context";
const spinner = (
  <div className="spinner-border" role="status">
    <span className="sr-only"></span>
  </div>
);

function App() {
  const [userData, setuserData] = useState();
  return (
    <>
      <ThemeContext.Provider
        value={{ userInfo: [userData, setuserData] }}
      >
        <ToastContainer />

        <BrowserRouter history={history}>
          <Suspense fallback={spinner}>
            <AppRoutes />
          </Suspense>
        </BrowserRouter>
      </ThemeContext.Provider>
    </>
  );
}

export default memo(App);
