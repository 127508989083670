import React from "react";
import { Navigate } from "react-router-dom";

const DasboardContainer = React.lazy(() =>
  import("../containers/Dasboard/Dashboard")
);
const NotificationContainer = React.lazy(() =>
  import("../containers/pushNotification/pushnotification")
);

const Redirect = () => (
  <>
    <Navigate to="/dashboard" />
  </>
);

const RouteList = [
  {
    path: "/dashboard",
    name: "Dasboard",
    exact: true,
    component: DasboardContainer,
  },
  {
    path: "/",
    name: "Register",
    exact: true,
    component: Redirect,
  },
  {
    path: "/push_notification",
    name: "Notification",
    exact: true,
    component: NotificationContainer,
  },
];

export default RouteList;
