import React, { Suspense, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import RouteList from "../routes/RouterList";
import SiteAuthorisation from "./SiteAuthorisation";
import { io } from "socket.io-client";
const spinner = (
  <div className="spinner-border" role="status">
    <span className="sr-only"></span>
  </div>
);

const SiteContent = () => {
  const [userID, setUserID] = useState();
  useEffect(() => {
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    console.log("userinfo", userinfo);
    if (userinfo !== undefined) setUserID(userinfo?.id);
  }, []);
  console.log("userID.toString()", userID?.toString());
  useEffect(() => {
    const socket = JSON.parse(localStorage.getItem("socket"));
    if (socket) {
    
    }
  }, [userID]);

  return (
    <Suspense fallback={spinner}>
      <Routes>
        {RouteList &&
          RouteList.map(
            (route) =>
              route.component && (
                <Route
                  key={route.name}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={
                    <SiteAuthorisation
                      name={route.name}
                      type={route.type}
                      key={route.name}
                    >
                      <route.component />
                    </SiteAuthorisation>
                  }
                />
              )
          )}

        {/* <Redirect from="/" to="/users-list" /> */}
      </Routes>
    </Suspense>
  );
};

export default SiteContent;
