import { authRequest, request } from "./Request.js";

export function Login(props) {
  let payload = {
    ...props,
    device_token: localStorage.getItem("NotificatioToken"),
  };
  return request({
    url: "/auth/login",
    method: "post",
    data: payload,
  });
}

export function ForgotPassword(props) {
  return request({
    url: "/auth/forgotpassword",
    method: "post",
    data: props,
  });
}
export function restPassword(props) {
  return request({
    url: `/auth/resetpassword/${props?.token}`,
    method: "post",
    data: { password: props?.password },
  });
}


export function ChnagePassword(props) {
  return authRequest({
    url: "/admin/updatepassword",
    method: "put",
    data: props,
  });
}
export function renew(props) {
  return request({
    url: "/auth/refreshtoken",
    method: "post",
    data: props,
  });
}
export function AdminList(props) {
  return authRequest({
    url: `/admin/list`,
    params: props,
    method: "Get",
  });
}
export function Notify(props){
  return authRequest({
    url: "/ntf/v2/create",
    method: "post",
    data: props,
  });
}
export function getAdminList(props) {
  return authRequest({
    url:
      props?.id !== undefined
        ? `/admin/list/${props?.id}`
        : `/admin/list`,
    method: "Get",
  });
}

export function AdminAdd(props) {
  return authRequest({
    url: "/admin/create",
    method: "post",
    data: props,
  });
}

export function EditAdmin(props) {
  return authRequest({
    url: "/admin/update",
    method: "put",
    data: props,
  });
}
export function AdminDelete(props) {
  return authRequest({
    url: "/admin/delete",
    method: "delete",
    data: props,
  });
}

//contest
export function ContestList(props) {
  return authRequest({
    url:
      props?.page !== undefined
        ? `/event/list?page=${props.page}`
        : `/event/list`,
    method: "Get",
  });
}
export function getContestList(props) {
  return authRequest({
    url:
      props?.id !== undefined ? `/event/list/${props?.id}` : `/event/list`,
    method: "Get",
  });
}

export function ContestDelete(props) {
  return authRequest({
    url: "/event/delete",
    method: "delete",
    data: props,
  });
}
export function Fileupload(props) {
  return authRequest({
    url: "/media/upload",
    method: "post",
    data: props,
  });
}
export function AddContest(props) {
  return authRequest({
    url: "/event/create",
    method: "post",
    data: props,
  });
}

export function EditContest(props) {
  return authRequest({
    url: "/event/update",
    method: "put",
    data: props,
  });
}
//partner
export function PartnerList(props) {
  return authRequest({
    url:
      props?.page !== undefined
        ? `/partner/list?page=${props.page}`
        : `/partner/list`,
    method: "Get",
  });
}
export function getPartnerList(props) {
  return authRequest({
    url:
      props?.id !== undefined
        ? `/partner/list/${props?.id}`
        : `/partner/list`,
    method: "Get",
  });
}

export function PartnerAdd(props) {
  return authRequest({
    url: "/partner/create",
    method: "post",
    data: props,
  });
}

export function EditPartner(props) {
  return authRequest({
    url: "/partner/update",
    method: "put",
    data: props,
  });
}
export function PartnerDelete(props) {
  return authRequest({
    url: "/partner/delete",
    method: "delete",
    data: props,
  });
}

export function logout(props) {
  let payload = {
    email: localStorage.getItem("email"),
    device_token: localStorage.getItem("NotificatioToken"),
  };
  return authRequest({
    url: "/auth/removetoken",
    method: "post",
    data: payload,
  });
}
export function Notification(props) {
  return authRequest({
    url: "/ntf/create",
    method: "post",
    data: props,
  });
}
//Radio

export function RadioList(props) {
  return authRequest({
    url: `/media/admin/radio`,
    params: props,
    method: "Get",
  });
}

export function sendRadioList(props) {
  return authRequest({
    url: `/media/admin/radio`,
    method: "post",
    data: props,
  });
}
