import React, { memo } from "react";
import { Route, Routes } from "react-router-dom";
import SiteContent from "../Layout/SiteContent";
const LoginContainer = React.lazy(() => import("../containers/Login/signIn"));
const ForgotContainer = React.lazy(() =>
  import("../containers/ForgotPassword/forgotpassword")
);
const ResetPassword = React.lazy(() =>
  import("../containers/RestPassword/Restpassword")
);
const AppRoutes = () => {
  return (
    <>
      <div className="main-section">
        <Routes>
          <Route
            path="/login"
            exact={true}
            name="login"
            element={<LoginContainer />}
          />
          <Route
            path="/reset-password/:token"
            exact={true}
            name="reset-password"
            element={<ResetPassword />}
          />
          <Route
            path="/forgotPassword"
            exact={true}
            name="forgot"
            element={<ForgotContainer />}
          />

          <Route path="*" element={<SiteContent />} />
        </Routes>
      </div>
    </>
  );
};

export default AppRoutes;
